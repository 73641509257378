import React from 'react';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import {
  Button,
  DialogContent,
  DialogActions,
  Grid,
  Fade,
} from '@mui/material';

import Form, { TextField } from 'src/components/ui-components/Form';
import CheckField from 'src/components/ui-components/Form/CheckField';
import {
  AssigneesAutocomplete,
  FilecasesAutocomplete,
  EntitiesAutocomplete,
  TaskStatusAutocomplete,
  CalendarsAutocomplete,
} from 'src/autocompletes';
import {
  LSPin as LSPinIcon,
  LSDangerSign as LSDangerSignIcon,
} from 'src/components/icons';

import { LoadingButton } from '@mui/lab';
import { PRIMARY_DASHED } from 'src/config/mui-theme/components/MuiButton/variants';

import { getEventFormSubmitText } from 'src/dialogs/calendar/EventFormDialog';
import CalendarEventFormSchema from './validation-schema';
import DateBlockField from './DateBlockField';

const CalendarEventForm = ({
  enableReinitialize,
  initialValues = {},
  disabledFields = {},
  onSubmit,
  onCancel,
  clone,
}) => {
  const handleChangeEventType = (newType, formikBag) => {
    if (newType !== null) {
      formikBag.setFieldValue('type', newType);
    }
  };

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      validationSchema={CalendarEventFormSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <>
          <DialogContent>
            <Form id="calendarEventForm">
              <Grid container item gap={3} xs={12}>
                <Grid item md sx={{ mt: 1 }} xs={12}>
                  <Button
                    startIcon={<LSDangerSignIcon />}
                    sx={{
                      ...(values.type === 'alert' && {
                        ...PRIMARY_DASHED.style['&:hover'],
                      }),
                    }}
                    value="alert"
                    variant="dashed"
                    onClick={() =>
                      handleChangeEventType('alert', { setFieldValue })
                    }
                  >
                    Alerta
                  </Button>
                </Grid>

                <Grid item md sx={{ mt: 1 }} xs={12}>
                  <Button
                    startIcon={<LSPinIcon />}
                    sx={{
                      ...(values.type === 'task' && {
                        ...PRIMARY_DASHED.style['&:hover'],
                      }),
                    }}
                    value="task"
                    variant="dashed"
                    onClick={() =>
                      handleChangeEventType('task', {
                        setFieldValue,
                      })
                    }
                  >
                    Tarea
                  </Button>
                </Grid>

                <Fade in={values.type === 'task'}>
                  <Grid item md={6} xs={12}>
                    <TaskStatusAutocomplete
                      label="Estado"
                      name="taskState"
                      placeholder="Ej. Cerrado"
                    />
                  </Grid>
                </Fade>
              </Grid>

              <CalendarsAutocomplete
                disabled={!!initialValues?.googleCalendarData && !clone}
                getOptionDisabled={
                  initialValues?.id
                    ? (option) => !!option.googleCalendarId && !clone
                    : undefined
                }
                name="calendar"
              />

              <TextField
                label="Titulo"
                name="title"
                placeholder="Ej. Junta con cliente"
              />

              <TextField
                multiline
                label="Description"
                name="description"
                rows={3}
              />

              <DateBlockField />

              {/* <CheckField
                label="Todo el dia"
                name="allDay"
                onChange={() => {
                  setFieldValue('start', dayjs(values.start).startOf('day'));
                  setFieldValue('end', dayjs(values.start).endOf('day'));
                }}
              /> */}

              <FilecasesAutocomplete
                disabled={disabledFields.filecase}
                name="filecase"
              />

              <AssigneesAutocomplete multiple name="assigned" />

              <EntitiesAutocomplete multiple name="entities" />
            </Form>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={!!isSubmitting}
              sx={{ mr: 'auto' }}
              onClick={onCancel}
            >
              Cancelar
            </Button>

            <LoadingButton
              disabled={isSubmitting}
              form="calendarEventForm"
              loading={isSubmitting}
              sx={{ px: 4 }}
              type="submit"
              variant="contained"
            >
              {getEventFormSubmitText({ eventId: values.id, clone })}
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

export default CalendarEventForm;
